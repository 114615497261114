import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useAtom, Provider } from "jotai";
import { authAtom } from "../atoms/authAtom";
// import { setCookie } from "../utils/CustomCookies";

const AuthContext = createContext({});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const [, setAuthState] = useAtom(authAtom);

  useEffect(() => {
    console.log(
      "Keycloak initialized:",
      initialized,
      "Authenticated:",
      keycloak.authenticated
    );
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login().catch((error) => {
          console.error("Login failed:", error);
        });
      }
    }
  }, [keycloak, initialized]);

  const handleTokenRefresh = useCallback(() => {
    const intervalId = setInterval(() => {
      if (keycloak.authenticated) {
        keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token was successfully refreshed");
              // setCookie("access_token", keycloak.token as string);
              // setCookie("refresh_token", keycloak.refreshToken as string);
              setAuthState((auth) => ({
                ...auth,
                accessToken: keycloak.token,
                refreshToken: keycloak.refreshToken,
                tokenParsed: keycloak.tokenParsed,
              }));
            }
          })
          .catch((err) => {
            console.error("Failed to refresh the token", err);
          });
      }
    }, 60000); // Refresh every 60 seconds
    return () => clearInterval(intervalId); // Cleanup function
  }, [keycloak]); // Dependencies for useCallback

  useEffect(() => {
    if (keycloak.authenticated) {
      if (keycloak.token) {
        console.log("useEffect token");
        // setCookie("access_token", keycloak.token);
        setAuthState((auth) => ({ ...auth, accessToken: keycloak.token }));
      }
      if (keycloak.refreshToken) {
        console.log("useEffect refresh");
        // setCookie("refresh_token", keycloak.refreshToken);
        setAuthState((auth) => ({
          ...auth,
          refreshToken: keycloak.refreshToken,
        }));
      }
      if (keycloak.tokenParsed) {
        console.log("useEffect tokenParsed");
        setAuthState((auth) => ({
          ...auth,
          tokenParsed: keycloak.tokenParsed,
        }));
      }
      handleTokenRefresh();
    }
  }, [
    keycloak.authenticated,
    keycloak.token,
    keycloak.refreshToken,
    keycloak.tokenParsed,
    setAuthState,
    handleTokenRefresh,
  ]);

  if (!initialized || !keycloak.authenticated) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ keycloak, initialized }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
