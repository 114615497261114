import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import "tailwindcss/tailwind.css";
import { customAxios } from "../utils/CustomAxios";
import "../App.css";
import MemberForm from "./MemberForm";
import { submitMember, submitMemberEdit } from "../api/apiService";
import { useAtom } from "jotai";
import { authAtom } from "../atoms/authAtom";

interface Member {
  id: number;
  memberNo: number;
  memberStatus: string;
  memberName: string;
  memberEngName?: string;
  tel1?: string;
  prfrArGrade: number;
}

interface ApiResponse {
  code: number;
  message: string;
  data: {
    content: Member[];
    totalPages: number;
    totalElements: number;
  };
}

const filterOptions = [
  { value: "memberName", label: "Member Name", type: "text" },
  {
    value: "memberStatus",
    label: "Member Status",
    type: "select",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
      { value: "SUSPENDED", label: "Suspended" },
      { value: "PENDING_ACTIVATION", label: "Pending Activation" },
      { value: "DELETED", label: "Deleted" },
    ],
  },
  { value: "tel1", label: "Telephone", type: "text" },
  { value: "memberEngName", label: "English Name", type: "text" },
];

const Hoewon: React.FC = () => {
  const [authState] = useAtom(authAtom);
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddMemberOpen, setIsAddMemberOpen] = useState<boolean>(false);
  const [memberForm, setMemberForm] = useState({
    memberName: "",
    memberEngName: "",
    memberStatus: "ACTIVE",
    prfrArGrade: 0,
  });
  const [errors, setErrors] = useState({
    memberName: "",
    memberEngName: "",
    prfrArGrade: 0,
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);

  // handleInputChange 를 위해서 FieldTypes 사전 정의
  interface FieldTypes {
    [key: string]: "int" | "float";
  }
  const fieldTypes: FieldTypes = {
    prfrArGrade: "float",
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;

    // 필드 타입에 따라 숫자 변환 방식을 결정
    switch (fieldTypes[name]) {
      case "float":
        const floatValue = parseFloat(value);
        if (!isNaN(floatValue)) {
          setMemberForm((prev) => ({
            ...prev,
            [name]: floatValue,
          }));
        } else {
          setMemberForm((prev) => ({
            ...prev,
            [name]: value ? floatValue : "",
          }));
        }
        break;
      case "int":
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
          setMemberForm((prev) => ({
            ...prev,
            [name]: intValue,
          }));
        } else {
          setMemberForm((prev) => ({
            ...prev,
            [name]: value ? intValue : "",
          }));
        }
        break;
      default:
        setMemberForm((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
    }
  };

  const handleOpenAddMember = (member?: Member) => {
    if (member) {
      setSelectedMember(member);
      setMemberForm({
        memberName: member.memberName,
        memberEngName: member.memberEngName || "",
        memberStatus: member.memberStatus,
        prfrArGrade: member.prfrArGrade,
      });
    } else {
      setSelectedMember(null);
      setMemberForm({
        memberName: "",
        memberEngName: "",
        memberStatus: "ACTIVE",
        prfrArGrade: 0,
      });
    }
    setIsAddMemberOpen(true);
  };

  const handleCloseAddMember = () => {
    setIsAddMemberOpen(false);
    setMemberForm({
      memberName: "",
      memberEngName: "",
      memberStatus: "ACTIVE",
      prfrArGrade: 0,
    });
    setErrors({ memberName: "", memberEngName: "", prfrArGrade: 0 });
    setSelectedMember(null);
  };

  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[0].value
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const [pagination, setPagination] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const selectedOption = filterOptions.find(
    (option) => option.value === selectedFilter
  );

  const handleAddMember = async () => {
    console.log("Submitting form", memberForm);

    // Initialize error state to no errors
    setErrors({ memberName: "", memberEngName: "", prfrArGrade: 0 });

    // Check for validation errors
    let hasError = false;
    const newErrors = {
      memberName: "",
      memberEngName: "",
      prfrArGrade: 0,
    };

    if (!memberForm.memberName) {
      newErrors.memberName = "Member name is required";
      hasError = true;
    }
    if (!memberForm.memberEngName) {
      newErrors.memberEngName = "Member English name is required";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    setFormSubmitting(true);
    if (selectedMember) {
      await updateMember();
    } else {
      await newMember();
    }
  };

  const newMember = async () => {
    try {
      const response = await submitMember(
        memberForm.memberStatus,
        memberForm.memberName,
        memberForm.memberEngName,
        "",
        "",
        memberForm.prfrArGrade
      );

      if (response) {
        console.log("Form submitted successfully", response);
        setIsAddMemberOpen(false); // Close dialog on success
        fetchMembers();
      } else {
        console.error("Failed to create:", response.message);
      }
    } catch (error) {
      console.error("Error creating:", error);
    } finally {
      setFormSubmitting(false);
    }
  };

  const updateMember = async () => {
    try {
      const response = await submitMemberEdit(
        selectedMember?.memberNo ?? 0,
        memberForm.memberStatus,
        memberForm.memberName,
        memberForm.memberEngName,
        "",
        "",
        memberForm.prfrArGrade
      );

      if (response) {
        console.log("Member updated successfully", response);
        setIsAddMemberOpen(false); // Close dialog on success
        fetchMembers();
      } else {
        console.error("Failed to update member:", response.message);
      }
    } catch (error) {
      console.error("Error updating member:", error);
    } finally {
      setFormSubmitting(false);
    }
  };

  const fetchMembers = async () => {
    setLoading(true);
    const params = new URLSearchParams({
      page: (pagination.page + 1).toString(),
      size: pagination.pageSize.toString(),
      ...(filterValue && { [selectedFilter]: filterValue }),
    });

    try {
      const response = await customAxios.get<ApiResponse>(
        `/arbp/members?${params.toString()}`
      );
      if (response.data.code === 200) {
        setMembers(
          response.data.data.content.map((member) => ({
            ...member,
            id: member.memberNo,
          }))
        );
        setTotalElements(response.data.data.totalElements);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Failed to fetch members:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [
    selectedFilter,
    filterValue,
    pagination.page,
    pagination.pageSize,
    authState,
  ]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(event.target.value);
    setFilterValue(""); // Reset filter value on filter change
  };

  const handleValueChange = (event: SelectChangeEvent<string>) => {
    setFilterValue(event.target.value);
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPagination({
      ...pagination,
      page: newModel.page,
      pageSize: newModel.pageSize,
    });
  };

  const handleRowDoubleClick = (params: any) => {
    const selectedMember = members.find(
      (member) => member.id === params.row.id
    );
    if (selectedMember) {
      handleOpenAddMember(selectedMember);
    }
  };

  const columns: GridColDef[] = [
    { field: "memberNo", headerName: "회원번호", width: 130 },
    { field: "memberName", headerName: "이름", width: 200 },
    { field: "memberEngName", headerName: "별명", width: 200 },
    { field: "memberStatus", headerName: "상태", width: 180 },
  ];

  const getRowId = (row: Member) => row.id;

  return (
    <div className="content">
      <Box className="p-4">
        <Button
          variant="contained"
          onClick={() => handleOpenAddMember()}
          className="mb-4"
        >
          Add New Member
        </Button>
        <FormControl fullWidth margin="normal">
          <InputLabel id="filter-label">Search By</InputLabel>
          <Select
            labelId="filter-label"
            value={selectedFilter}
            label="Search By"
            onChange={handleFilterChange}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedOption?.type === "select" ? (
          <FormControl fullWidth margin="normal">
            <InputLabel id="value-label">{selectedOption.label}</InputLabel>
            <Select
              labelId="value-label"
              value={filterValue}
              label={selectedOption.label}
              onChange={handleValueChange}
            >
              {selectedOption.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            fullWidth
            label={selectedOption?.label}
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
            margin="normal"
            variant="outlined"
          />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setFilterValue("")}
          className="my-2"
        >
          Clear Filter
        </Button>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="overflow-x-auto overflow-y-hidden w-full"> {/* 좌우 스크롤 허용, 상하 스크롤 제거 */}
            <DataGrid
              rows={members}
              getRowId={getRowId}
              columns={columns}
              paginationModel={pagination}
              onPaginationModelChange={handlePaginationModelChange}
              pageSizeOptions={[5, 10, 25]}
              // checkboxSelection
              pagination
              paginationMode="server"
              rowCount={totalElements}
              onRowDoubleClick={handleRowDoubleClick}
            />
          </div>
        )}

        {isAddMemberOpen && (
          <MemberForm
            memberForm={memberForm}
            errors={errors}
            formSubmitting={formSubmitting}
            handleInputChange={handleInputChange}
            handleCloseAddMember={handleCloseAddMember}
            handleAddMember={handleAddMember}
            isAddMemberOpen={isAddMemberOpen}
          />
        )}
      </Box>
    </div>
  );
};

export default Hoewon;
