import { useEffect } from "react";
import { useAtom } from "jotai";
import { authAtom } from "../atoms/authAtom";
import { customAxios } from "../utils/CustomAxios";

const setAuthorizationHeader = (accessToken: string | undefined) => {
  if (accessToken) {
    customAxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  } else {
    delete customAxios.defaults.headers.common["Authorization"];
  }
};

export const useAuthInterceptor = () => {
  const [authState] = useAtom(authAtom);
  const { accessToken } = authState;

  useEffect(() => {
    setAuthorizationHeader(accessToken);
  }, [accessToken]);
};
