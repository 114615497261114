import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { memberAtom } from "../atoms/memberAtom";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { atom, useAtom } from "jotai";
import { customAxios } from "../utils/CustomAxios";
import "../App.css";
import { authAtom } from "../atoms/authAtom";
import { dateAtom } from "../atoms/atoms";
import { deToast } from "../utils/DeToast";
import { extErrDetails } from "../utils/ErrorHandleUtil";

interface RentDto {
  rentNo: number;
  bookNo: string;
  title: string;
  memberNo: number;
  memberName: string;
  returnDueDate: string;
  extendCnt: number | null;
  rentStatus: string;
  crtTime: string | null;
}

interface ApiResponse {
  code: number;
  message: string;
  data: {
    content: RentDto[];
    totalPages: number;
    totalElements: number;
  };
}

interface Filters {
  rentNo: string;
  bookNo: string;
  memberNo: string;
  returnDueDate: string;
  extendCnt: string;
  rentStatus: string;
}

const rentDtoAtom = atom<RentDto[]>([]);
const errorAtom = atom<string | null>(null);

const RentHistory: React.FC = () => {
  const [authState] = useAtom(authAtom);
  const [
    { memberNo, memberName, memberEngName, qty, scopeTimesTen },
    setMemberState,
  ] = useAtom(memberAtom);
  const navigate = useNavigate();

  const [rentDtos, setRentDtos] = useAtom(rentDtoAtom);
  const [filters, setFilters] = useState<Filters>({
    rentNo: "",
    bookNo: "",
    memberNo: "",
    returnDueDate: "",
    extendCnt: "",
    rentStatus: "",
  });

  const [pagination, setPagination] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });
  const [error, setError] = useAtom(errorAtom);
  const [totalElements, setTotalElements] = useState(0);
  const [cancel, setCancel] = useState(0);

  useEffect(() => {
    setError(null); // fetch 시 error 는 다시 초기화

    const fetchRentDtos = async () => {
      try {
        if (authState.accessToken) {
          const query = Object.entries(filters)
            .filter(([_, value]) => value !== "")
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");

          const queryPagination = `page=${pagination.page + 1}&size=${
            pagination.pageSize
          }`;

          const response = await customAxios.get<ApiResponse>(
            `${process.env.REACT_APP_BACKEND_URL}arbp/rents?${query}&${queryPagination}`
          );
          if (response.data.code === 200) {
            const dataWithId = response.data.data.content.map((dto) => ({
              ...dto,
              id: dto.rentNo,
            }));
            setRentDtos(dataWithId);
            setTotalElements(response.data.data.totalElements);
          } else {
            setError("Failed to fetch jobs.");
          }
        }
      } catch (err) {
        console.log(err);
        setError("An error occurred while fetching jobs.");
      }
    };
    fetchRentDtos();
  }, [filters, pagination.page, pagination.pageSize, authState, cancel]);

  const handleCancel = async (id: number) => {
    try {
      const response = await customAxios.post(`arbp/rent/update`, {
        rentStatus: "Canceled",
        rentNo: id,
      });

      if (response.status === 200) {
        deToast("업데이트 되었습니다", response.status);
        setCancel((prev) => prev + 1);
      } else {
        deToast("오류", response.status);
      }
    } catch (error) {
      deToast(extErrDetails(error).message || "", 400);
    }
  };
  // const handleInputChange = (
  //   e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  // ) => {
  //   const { name, value } = e.target as HTMLInputElement;
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | { name: string; value: string };
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: value,
  //   }));
  // };

  const handleFilterSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPagination({
      ...pagination,
      page: newModel.page,
      pageSize: newModel.pageSize,
    });
  };

  const columns: GridColDef[] = [
    { field: "rentNo", headerName: "대여번호", width: 100 },
    {
      field: "bookNo",
      headerName: "도서번호",
      width: 150,
    },
    { field: "title", headerName: "도서명", width: 250 },
    { field: "memberNo", headerName: "멤버번호", width: 100 },
    { field: "memberName", headerName: "멤버이름", width: 120 },
    {
      field: "returnDueDate",
      headerName: "반납예정일",
      width: 120,
      valueFormatter: (params) => params.value?.slice(0, 10),
    },
    {
      field: "crtTime",
      headerName: "생성일",
      width: 120,
      valueFormatter: (params) => params.value?.slice(0, 10),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { id, rentStatus } = params.row;
        return rentStatus === "Rented" || rentStatus === "Returned" ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <span>{rentStatus}</span>
            {(rentStatus === "Rented" || rentStatus === "Returned") && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCancel(id)}
                style={{ width: "50px" }}
              >
                취소
              </Button>
            )}
          </Box>
        ) : (
          <span>{rentStatus}</span>
        );
      },
    },
  ];

  return (
    <div className="content">
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <form onSubmit={handleFilterSubmit}>
          <TextField
            name="rentNo"
            label="Rent No."
            value={filters.rentNo}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mb: 2, mr: 2 }}
          />
          <TextField
            name="bookNo"
            label="BookNo.(입력번호로 시작하는)"
            value={filters.bookNo}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mb: 2, mr: 2 }}
          />
          <TextField
            name="memberNo"
            label="Member No."
            value={filters.memberNo}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mb: 2, mr: 2 }}
          />
          <TextField
            name="returnDueDate"
            label="Return Due Date"
            type="date"
            value={filters.returnDueDate || "-"}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mb: 2, mr: 2 }}
          />
          <TextField
            name="extendCnt"
            label="Extend Count"
            value={filters.extendCnt}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mb: 2, mr: 2 }}
          />
          <FormControl variant="outlined" sx={{ mb: 2, mr: 2, minWidth: 100 }}>
            <InputLabel>Rent Status</InputLabel>
            <Select
              name="rentStatus"
              value={filters.rentStatus}
              onChange={handleInputChange}
              label="Rent Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Returned">Returned</MenuItem>
              <MenuItem value="Rented">Rented</MenuItem>
              <MenuItem value="Canceled">Canceled</MenuItem>
            </Select>
          </FormControl>
          {/* <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mb: 2 }}
          >
            Apply Filters
          </Button> */}
        </form>
        {error ? (
          <p>Error: {error}</p>
        ) : (
          <div className="overflow-x-auto overflow-y-hidden w-full"> {/* 좌우 스크롤 허용, 상하 스크롤 제거 */}
            <DataGrid
              paginationModel={pagination}
              onPaginationModelChange={handlePaginationModelChange}
              pagination
              paginationMode="server"
              rowCount={totalElements}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5",
                  "& .MuiDataGrid-columnHeader": {
                    borderRight: "1px solid #d3d3d3",
                  },
                },
                "& .MuiDataGrid-cell": {
                  borderRight: "1px solid #ccc",
                },
              }}
              rows={rentDtos}
              columns={columns}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default RentHistory;
