import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import {
  Container,
  TextField,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { atom, useAtom } from "jotai";
import { customAxios } from "../utils/CustomAxios";
import "../App.css";
import { authAtom } from "../atoms/authAtom";
import { deToast } from "../utils/DeToast";
import { toast } from "react-toastify";
import { extErrDetails } from "../utils/ErrorHandleUtil";

interface Order {
  orderNo: number;
  memberNo: number;
  memberName: string | null;
  serviceNo: number;
  serviceNm: string | null;
  orderWeekQty: number;
  serviceStartDate: string;
  serviceEndDate: string;
  cyclDayNo: number;
  cyclWeekday: number;
  appliedArLvlGrade: number;
  appliedBookQty: number;
  arProductId: string | null;
  crtTime: string;
  mdfTime: string | null;
}

interface ApiResponse {
  code: number;
  message: string;
  data: {
    content: Order[];
    totalElements: number;
  };
}

const ordersAtom = atom<Order[]>([]);
const errorAtom = atom<string | null>(null);
const totalElementsAtom = atom<number>(0);

const Order: React.FC = () => {
  const [authState] = useAtom(authAtom);
  const navigate = useNavigate();
  const [orders, setOrders] = useAtom(ordersAtom);
  const [error, setError] = useAtom(errorAtom);
  const [totalElements, setTotalElements] = useAtom(totalElementsAtom);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [editingOrder, setEditingOrder] = useState<Partial<Order>>({});
  const [open, setOpen] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    setError(null); // fetch 시 error 는 다시 초기화

    const fetchOrderDtos = async () => {
      try {
        if (authState.accessToken) {
          const query = Object.entries(filters)
            .filter(([_, value]) => value !== "")
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");

          const queryPagination = `page=${pagination.page + 1}&size=${
            pagination.pageSize
          }`;

          const response = await customAxios.get<ApiResponse>(
            `${process.env.REACT_APP_BACKEND_URL}arbp/orders?${query}&${queryPagination}`
          );
          if (response.data.code === 200) {
            const dataWithId = response.data.data.content.map((dto) => ({
              ...dto,
              id: dto.orderNo,
            }));
            setOrders(dataWithId);
            setTotalElements(response.data.data.totalElements);
          } else {
            setError("Failed to fetch orders.");
          }
        }
      } catch (err) {
        console.log(err);
        setError("An error occurred while fetching orders.");
      }
    };
    fetchOrderDtos();
  }, [filters, pagination.page, pagination.pageSize, authState, open]);

  const handleEdit = (order: Order) => {
    setEditingOrder(order);
    setOpen(true);
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPagination({
      ...pagination,
      page: newModel.page,
      pageSize: newModel.pageSize,
    });
  };

  const handleSave = async () => {
    setValidationError(null);
    // 데이터 검증 로직
    if (
      !editingOrder.memberNo ||
      !editingOrder.serviceNo ||
      (editingOrder.serviceNo === 1 && !editingOrder.orderWeekQty) ||
      (editingOrder.serviceNo === 1 && !editingOrder.cyclDayNo) ||
      (editingOrder.serviceNo === 1 && !editingOrder.cyclWeekday) ||
      (editingOrder.serviceNo === 1 && !editingOrder.appliedArLvlGrade) ||
      (editingOrder.serviceNo === 1 && !editingOrder.appliedBookQty) ||
      (editingOrder.serviceNo === 2 && !editingOrder.arProductId) ||
      !editingOrder.serviceStartDate ||
      !editingOrder.serviceEndDate
    ) {
      setValidationError("모든 필수 입력 항목을 채워주세요.");
      return;
    }

    if (
      editingOrder.serviceNo === 1 &&
      (!editingOrder.appliedArLvlGrade ||
        isNaN(editingOrder.appliedArLvlGrade) ||
        editingOrder.appliedArLvlGrade < 0 ||
        editingOrder.appliedArLvlGrade > 15 ||
        (editingOrder.appliedArLvlGrade.toString().includes(".") &&
          editingOrder.appliedArLvlGrade.toString().split(".")[1].length > 1))
    ) {
      setValidationError(
        "적용 AR 레벨 등급을 올바르게 입력해주세요. (0에서 15 사이의 숫자, 소수점 한 자리허용)"
      );
      return;
    }

    // 데이터 저장 로직 (추가/수정 공용)
    try {
      const response = await customAxios.post("/arbp/order", editingOrder);
      if (response.status === 200) {
        console.log("Status is 200, calling deToast with:", response.data);
        deToast("success", response.status);

        setOpen(false);
        setEditingOrder({});
        setValidationError(null);
      } else {
        deToast(response.data || "", response.status);
      }
    } catch (error) {
      deToast(extErrDetails(error).message || "", 400);
    }
  };

  const columns: GridColDef[] = [
    { field: "orderNo", headerName: "주문번호", width: 70 },
    { field: "memberNo", headerName: "회원번호", width: 70 },
    { field: "memberName", headerName: "회원이름", width: 100 },
    { field: "serviceNo", headerName: "서비스번호", width: 100 },
    { field: "serviceNm", headerName: "서비스명", width: 100 },
    { field: "orderWeekQty", headerName: "주문 수량", width: 70 },
    {
      field: "serviceStartDate",
      headerName: "서비스시작일",
      width: 100,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
    },
    {
      field: "serviceEndDate",
      headerName: "서비스종료일",
      width: 100,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
    },
    { field: "cyclDayNo", headerName: "주기 일수", width: 70 },
    { field: "cyclWeekday", headerName: "주기 요일(월~금 : 1~5)", width: 90 },
    {
      field: "appliedArLvlGrade",
      headerName: "적용AR레벨",
      width: 100,
    },
    { field: "appliedBookQty", headerName: "적용주문수량", width: 90 },
    { field: "arProductId", headerName: "AR Product ID", width: 120 },
    {
      field: "crtTime",
      headerName: "생성일",
      width: 210,
      valueFormatter: (params) => {
        if (!params.value) return "없음";
        const date = new Date(params.value);
        return date.toLocaleString("ko-KR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    {
      field: "mdfTime",
      headerName: "수정일",
      width: 210,
      valueFormatter: (params) => {
        if (!params.value) return "없음";
        const date = new Date(params.value);
        return date.toLocaleString("ko-KR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
  ];

  return (
    <div className="content">
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box>
            <TextField
              label="주문번호"
              variant="outlined"
              size="small"
              style={{ marginRight: 16 }}
              onChange={(e) =>
                setFilters({ ...filters, orderNo: e.target.value })
              }
            />
            <TextField
              label="회원번호"
              variant="outlined"
              size="small"
              style={{ marginRight: 16 }}
              onChange={(e) =>
                setFilters({ ...filters, memberNo: e.target.value })
              }
            />
            <TextField
              label="서비스번호"
              variant="outlined"
              size="small"
              style={{ marginRight: 16 }}
              onChange={(e) =>
                setFilters({ ...filters, serviceNo: e.target.value })
              }
            />
            <br></br>
            <br></br>
            <TextField
              label="서비스 시작일"
              variant="outlined"
              size="small"
              type="date"
              value={filters.serviceStartDate || "-"}
              InputLabelProps={{ shrink: true }}
              style={{ marginRight: 16 }}
              onChange={(e) =>
                setFilters({ ...filters, serviceStartDate: e.target.value })
              }
            />
            <TextField
              label="서비스 종료일"
              variant="outlined"
              size="small"
              type="date"
              value={filters.serviceEndDate || "-"}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setFilters({ ...filters, serviceEndDate: e.target.value })
              }
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit({} as Order)}
          >
            신규 생성
          </Button>
        </Box>
        <div className="overflow-x-auto overflow-y-hidden w-full"> {/* 좌우 스크롤 허용, 상하 스크롤 제거 */}
        <DataGrid
          paginationModel={pagination}
          onPaginationModelChange={handlePaginationModelChange}
          pagination
          paginationMode="server"
          rowCount={totalElements}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              "& .MuiDataGrid-columnHeader": {
                borderRight: "1px solid #d3d3d3",
              },
            },
            "& .MuiDataGrid-cell": {
              borderRight: "1px solid #ccc",
            },
          }}
          rows={orders}
          columns={columns}
          pageSizeOptions={[5, 10, 25]}
          onRowDoubleClick={(params) => handleEdit(params.row as Order)}
        />
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {editingOrder.orderNo ? "주문 수정" : "주문 생성"}
          </DialogTitle>
          <DialogContent>
            {validationError && (
              <p style={{ color: "red" }}>{validationError}</p>
            )}
            {editingOrder.orderNo && (
              <>
                <TextField
                  margin="dense"
                  label="Order No"
                  fullWidth
                  value={editingOrder.orderNo}
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Member No"
                  fullWidth
                  value={editingOrder.memberNo}
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Member Name"
                  fullWidth
                  value={editingOrder.memberName || ""}
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Service No (1: Rent 2: AR계정)"
                  fullWidth
                  value={editingOrder.serviceNo}
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Service Name"
                  fullWidth
                  value={editingOrder.serviceNm || ""}
                  disabled
                />
              </>
            )}
            {!editingOrder.orderNo && (
              <>
                <TextField
                  margin="dense"
                  label="Member No"
                  fullWidth
                  required
                  value={editingOrder.memberNo || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      memberNo: Number(e.target.value),
                    })
                  }
                />
                <TextField
                  margin="dense"
                  label="Service No (1: Rent 2: AR계정)"
                  fullWidth
                  required
                  value={editingOrder.serviceNo || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      serviceNo: Number(e.target.value),
                    })
                  }
                />
              </>
            )}
            {editingOrder.serviceNo === 1 && (
              <>
                <TextField
                  margin="dense"
                  label="Order Week Qty"
                  fullWidth
                  required
                  value={editingOrder.orderWeekQty || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      orderWeekQty: Number(e.target.value),
                    })
                  }
                />
                <TextField
                  margin="dense"
                  label="Cycle Day No. (일주일은 7 입력)"
                  fullWidth
                  required
                  value={editingOrder.cyclDayNo || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      cyclDayNo: Number(e.target.value),
                    })
                  }
                />
                <TextField
                  margin="dense"
                  label="Cycle Weekday. (요일 1~5)"
                  fullWidth
                  required
                  value={editingOrder.cyclWeekday || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      cyclWeekday: Number(e.target.value),
                    })
                  }
                />
                <TextField
                  margin="dense"
                  label="Applied AR Lvl Grade"
                  type="number"
                  inputProps={{ step: "0.1" }} // 소수점 한 자리까지 입력 가능
                  fullWidth
                  required
                  value={editingOrder.appliedArLvlGrade || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      appliedArLvlGrade: Number(e.target.value),
                    })
                  }
                />
                <TextField
                  margin="dense"
                  label="Applied Book Qty"
                  fullWidth
                  required
                  value={editingOrder.appliedBookQty || ""}
                  onChange={(e) =>
                    setEditingOrder({
                      ...editingOrder,
                      appliedBookQty: Number(e.target.value),
                    })
                  }
                />
              </>
            )}
            {editingOrder.serviceNo === 2 && (
              <TextField
                margin="dense"
                label="AR Product ID"
                fullWidth
                required
                value={editingOrder.arProductId || ""}
                onChange={(e) =>
                  setEditingOrder({
                    ...editingOrder,
                    arProductId: e.target.value,
                  })
                }
              />
            )}
            <TextField
              margin="dense"
              label="Service Start Date"
              fullWidth
              required
              type="date"
              value={
                editingOrder.serviceStartDate
                  ? new Date(editingOrder.serviceStartDate)
                      .toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .replace(/\. /g, "-")
                      .replace(".", "") // 'YYYY-MM-DD' 형식으로 변환
                  : "-"
              }
              onChange={(e) =>
                setEditingOrder({
                  ...editingOrder,
                  serviceStartDate: e.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              label="Service End Date"
              fullWidth
              required
              type="date"
              value={
                editingOrder.serviceEndDate
                  ? new Date(editingOrder.serviceEndDate)
                      .toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .replace(/\. /g, "-")
                      .replace(".", "") // 'YYYY-MM-DD' 형식으로 변환
                  : "-"
              }
              onChange={(e) =>
                setEditingOrder({
                  ...editingOrder,
                  serviceEndDate: e.target.value,
                })
              }
            />
            {editingOrder.orderNo && (
              <>
                <TextField
                  margin="dense"
                  label="Creation Time"
                  fullWidth
                  value={
                    editingOrder.crtTime
                      ? new Date(editingOrder.crtTime).toLocaleString("ko-KR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      : ""
                  }
                  disabled
                />
                <TextField
                  margin="dense"
                  label="Modification Time"
                  fullWidth
                  value={
                    editingOrder.mdfTime
                      ? new Date(editingOrder.mdfTime).toLocaleString("ko-KR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      : ""
                  }
                  disabled
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Order;
