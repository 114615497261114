import React, { useEffect, useState } from "react";
import { memberAtom } from "../atoms/memberAtom";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, TextField } from "@mui/material";
import { atom, useAtom } from "jotai";
import { customAxios } from "../utils/CustomAxios";
import "../App.css";
import { authAtom } from "../atoms/authAtom";
import { dateAtom } from "../atoms/atoms";

interface Job {
  id: number;
  orderNo: number;
  memberName: string;
  memberStatus: string;
  appliedArLvlGrade: number;
  appliedBookQty: number;
  addr1: string;
  tel1: string;
  aptComEntMthd: string;
  serviceStartDate: string;
  serviceEndDate: string;
  rentCount: number;
}

interface Holiday {
  holidayDate: string;
  holidayNm: string;
}

interface ApiResponse {
  code: number;
  message: string;
  data: Job[];
}

const jobsAtom = atom<Job[]>([]);
const errorAtom = atom<string | null>(null);

const TodayJob: React.FC = () => {
  const [authState] = useAtom(authAtom);
  const [
    { memberNo, memberName, memberEngName, qty, scopeTimesTen },
    setMemberState,
  ] = useAtom(memberAtom);
  const navigate = useNavigate();

  const [date, setDate] = useAtom(dateAtom);
  const [jobs, setJobs] = useAtom(jobsAtom);
  const [error, setError] = useAtom(errorAtom);
  const [holiday, setHoliday] = useState<Holiday | null>(null);
  const [futurePast, setFuturePast] = useState("");

  const handleMemberSelectRent = (
    selectedMemberNo: number,
    selectedMemberName: string,
    selectedMemberEngName: string
  ) => {
    setMemberState((prev) => ({
      ...prev,
      memberNo: selectedMemberNo || null,
      memberName: selectedMemberName || null,
      memberEngName: selectedMemberEngName || null,
    }));
    navigate("/rent");
  };

  const handleMemberSelectReturn = (
    selectedMemberNo: number,
    selectedMemberName: string,
    selectedMemberEngName: string
  ) => {
    setMemberState((prev) => ({
      ...prev,
      memberNo: selectedMemberNo || null,
      memberName: selectedMemberName || null,
      memberEngName: selectedMemberEngName || null,
    }));
    navigate("/return");
  };

  useEffect(() => {
    setError(null); // fetch 시 error 는 다시 초기화

    const fetchJobs = async () => {
      try {
        if (authState.accessToken) {
          const response = await customAxios.get<ApiResponse>(
            `${process.env.REACT_APP_BACKEND_URL}arbp/todayjob/${date}`
          );
          if (response.data.code === 200) {
            const jobsWithId = response.data.data.map((job) => ({
              ...job,
              id: job.orderNo, // Assuming 'orderNo' is unique
            }));
            setJobs(jobsWithId);
          } else {
            setError("Failed to fetch jobs.");
          }
        }
      } catch (err) {
        console.log(err);
        setError("An error occurred while fetching jobs.");
      }
    };
    fetchJobs();

    const fetchHolidays = async () => {
      try {
        if (authState.accessToken) {
          const response = await customAxios.get<Holiday, any>(
            `${process.env.REACT_APP_BACKEND_URL}arbp/holiday/${date}`
          );
          setHoliday(response.data);
        }
      } catch (err) {
        console.log(err);
        setError("An error occurred while fetching holiday.");
      }
    };
    fetchHolidays();

    // future 문구
    const fetchOrSetFuturePastString = async () => {
      const currentDate = new Date();
      console.log(date);
      console.log(currentDate.toISOString().split("T")[0]);

      if (date > currentDate.toISOString().split("T")[0]) {
        setFuturePast("주의 : 미래 날짜가 선택되어 있습니다");
      } else if (date < currentDate.toISOString().split("T")[0]) {
        setFuturePast("주의 : 과거 날짜가 선택되어 있습니다");
      } else {
        try {
          if (authState.accessToken) {
            const response = await customAxios.get<string, any>(
              `${process.env.REACT_APP_BACKEND_URL}arbp/goodwords`
            );
            setFuturePast(response.data);
          }
        } catch (err) {
          setError("An error occurred while fetching goodwords.");
          setFuturePast("");
        }
      }
    };
    fetchOrSetFuturePastString();
  }, [date, authState]);

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        const isEndDate = params.row.serviceEndDate.slice(0, 10) === date;
        const isStartDate = params.row.serviceStartDate.slice(0, 10) === date;
        console.log(params.row.serviceEndDate.slice(0, 10), date);

        const memberNo = params.row.memberNo;
        const memberName = params.row.memberName;
        const memberEngName = params.row.memberEngName;
        return (
          <>
            {!isEndDate && (
              <button
                className="bg-yellow-400 mx-1 text-white p-2 rounded shadow"
                onClick={() =>
                  handleMemberSelectRent(memberNo, memberName, memberEngName)
                }
              >
                {isStartDate ? "대여(첫날)" : "대여"}
              </button>
            )}
            {!isStartDate && (
              <button
                className="bg-blue-400 mx-1 text-white p-2 rounded shadow"
                onClick={() =>
                  handleMemberSelectReturn(memberNo, memberName, memberEngName)
                }
              >
                {isEndDate ? "반납(마지막)" : "반납"}
              </button>
            )}
          </>
        );
      },
    },
    { field: "orderNo", headerName: "주문no.", width: 60 },
    { field: "memberName", headerName: "이름", width: 150 },
    { field: "memberEngName", headerName: "별명", width: 160 },
    { field: "memberStatus", headerName: "상태", width: 100 },
    { field: "appliedArLvlGrade", headerName: "신청AR레벨", width: 90 },
    { field: "rentCount", headerName: "대여중", width: 70 },
    { field: "appliedBookQty", headerName: "대여수량", width: 70 },
    // { field: "addr1", headerName: "주소", width: 200 },
    // { field: "tel1", headerName: "전화번호", width: 120 },
    // { field: "aptComEntMthd", headerName: "출입정보", width: 160 },
    {
      field: "serviceStartDate",
      headerName: "서비스시작일",
      width: 100,
      valueFormatter: (params) => params.value?.slice(0, 10),
    },
    {
      field: "serviceEndDate",
      headerName: "서비스종료일",
      width: 100,
      valueFormatter: (params) => params.value?.slice(0, 10),
    },
  ];

  return (
    <div className="content">
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <h1 className="text-lg md:text-xl font-semibold text-gray-700 p-3 mb-2 bg-gray-50 shadow-sm rounded-lg">
          [오늘의 도서 대여] 기준일 :
        </h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            className="pl-2 w-full md:w-auto form-input mt-0 border-gray-300 rounded-md shadow-sm"
            type="date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            variant="outlined"
            sx={{
              mb: 2,
              width: "100%",
              maxWidth: "300px",
              borderRadius: "0.375rem",
            }}
          />
          {holiday && (
            <p style={{ color: "red", marginLeft: "16px", marginTop: "4px" }}>
              {holiday.holidayNm}
            </p>
          )}
          {futurePast && (
            <p style={{ color: "blue", marginLeft: "16px", marginTop: "4px" }}>
              {futurePast}
            </p>
          )}
        </div>
        {error ? (
          <p>Error: {error}</p>
        ) : (
          <div style={{ height: 700, width: "100%" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f5f5f5", // Optional: changes the background color of headers
                  "& .MuiDataGrid-columnHeader": {
                    borderRight: "1px solid #d3d3d3", // Applies a right border to each header cell
                  },
                },
                "& .MuiDataGrid-cell": {
                  borderRight: "1px solid #ccc", // Continues to apply a border to the right side of each cell
                },
              }}
              rows={jobs}
              columns={columns}
              pageSizeOptions={[5, 10, 25]}
              // Removing checkboxSelection as it's not needed
              disableRowSelectionOnClick
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default TodayJob;
