import axios, { AxiosInstance } from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const customAxios: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  //// moved to interceptor ////
  // headers: {
  //   Authorization: "Bearer " + getCookie("access_token"),
  //   "Content-Type": "application/json", // Content-Type 설정 추가
  // },
});

console.log(process.env.REACT_APP_LOGGING_ENABLED);

if (process.env.REACT_APP_LOGGING_ENABLED === "true") {
  // 요청 인터셉터 추가
  customAxios.interceptors.request.use(
    (config) => {
      console.log("Sending Request:", config);
      return config;
    },
    (error) => {
      console.error("Request Error:", error);
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 추가
  customAxios.interceptors.response.use(
    (response) => {
      console.log("Received Response:", response);
      return response;
    },
    (error) => {
      console.error("Response Error:", error);
      return Promise.reject(error);
    }
  );
}
