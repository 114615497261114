import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PublicHome from "./components/PublicHome";
import TodayJob from "./components/TodayJob";
import BarcodeReader from "./components/BarcodeReader";
import NotFound from "./components/NotFound";
import Header from "./components/Header";
import Hoewon from "./components/Hoewon";
import RentHistory from "./components/RentHistory";
import Order from "./components/Order";
import Book from "./components/Book";
import Report from "./components/Report";
import { AuthProvider } from "./auth/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const AppPublic: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        <ToastContainer position="top-right" autoClose={5000} />
        <Routes>
          <Route path="/" element={<PublicHome />} />
          <Route
            path="/today"
            element={
              <AuthProvider>
                <Header />
                <TodayJob />
              </AuthProvider>
            }
          />
          <Route
            path="/rent"
            element={
              <AuthProvider>
                <Header />
                <BarcodeReader taskType="rent" />
              </AuthProvider>
            }
          />
          <Route
            path="/return"
            element={
              <AuthProvider>
                <Header />
                <BarcodeReader taskType="return" />
              </AuthProvider>
            }
          />
          <Route
            path="/hoewon"
            element={
              <AuthProvider>
                <Header />
                <Hoewon />
              </AuthProvider>
            }
          />
          <Route
            path="/renthistory"
            element={
              <AuthProvider>
                <Header />
                <RentHistory />
              </AuthProvider>
            }
          />
          <Route
            path="/order"
            element={
              <AuthProvider>
                <Header />
                <Order />
              </AuthProvider>
            }
          />
          <Route
            path="/book"
            element={
              <AuthProvider>
                <Header />
                <Book />
              </AuthProvider>
            }
          />
          <Route
            path="/report"
            element={
              <AuthProvider>
                <Report />
              </AuthProvider>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default AppPublic;
