import { Typography, Button, Paper, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={6}
        style={{ padding: "20px", textAlign: "center", marginTop: "50px" }}
      >
        <Typography variant="h4" gutterBottom>
          404 - Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          Oops! The page you are looking for doesn't exist.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Go Home
        </Button>
      </Paper>
    </Container>
  );
}

export default NotFound;
