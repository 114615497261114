import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, yellow } from "@mui/material/colors";
import { useAtom } from "jotai";
import { authAtom } from "../atoms/authAtom";
import keycloak from "../auth/Keycloak";
import { useAuthInterceptor } from "../auth/AuthInterceptor";

const Header: React.FC = () => {
  const location = useLocation();
  const [authState] = useAtom(authAtom);
  const username = authState.tokenParsed?.preferred_username || "Unknown";
  useAuthInterceptor();

  const logout = () => {
    keycloak.logout();
  };

  // Determine if the current route is the home page
  const isHomePage = location.pathname === "/";

  // 테마 생성
  const theme = createTheme({
    palette: {
      primary: {
        main: yellow[100],
        light: yellow[300],
        dark: yellow[700],
        contrastText: yellow[900],
      },
      secondary: {
        main: grey[100],
        light: grey[300],
        dark: grey[500],
        contrastText: grey[700],
      },
    },
  });

  // State for managing the menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
  //   null
  // );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setSubMenuAnchorEl(event.currentTarget);
  // };

  // const handleSubMenuClose = () => {
  //   setSubMenuAnchorEl(null);
  // };

  return (
    <div className="headercss">
      <ThemeProvider theme={theme}>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ display: isHomePage ? "none" : "flex" }}
        >
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <img
                src="img/arbplogo.svg"
                alt="Logo"
                className="h-12 sm:block hidden"
              />
            </IconButton>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                letterSpacing: 2,
                display: { xs: "none", sm: "block" },
              }}
            >
              북플매니저
            </Typography>
            <Button color="inherit" component={Link} to="/today">
              오늘의 도서
            </Button>
            <Button color="inherit" component={Link} to="/rent">
              대여
            </Button>
            <Button color="inherit" component={Link} to="/return">
              반납
            </Button>
            <Button color="inherit" onClick={handleMenuOpen}>
              회원
            </Button>
            <Menu
              id="hoewon-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/hoewon">
                회원 정보
              </MenuItem>
              <MenuItem component={Link} to="/renthistory">
                대여 이력
              </MenuItem>
              <MenuItem component={Link} to="/order">
                등록 정보
              </MenuItem>
              <MenuItem component={Link} to="/book">
                도서 관리
              </MenuItem>
            </Menu>

            <Button
              variant="contained"
              color="secondary"
              onClick={logout}
              sx={{
                borderRadius: 3, // 둥근 모서리 적용
                padding: "6px 12px", // 버튼 내부 패딩 조정
                textTransform: "none", // 버튼 텍스트 대문자 변환 제거
                marginLeft: "32px",
                boxShadow: "0 4px 10px rgba(0,0,0,0.2)", // 그림자 효과 추가
              }}
            >
              Logout ({username})
            </Button>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
};

export default Header;
