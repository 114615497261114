import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { GridRowsProp, GridColDef } from "@mui/x-data-grid";

type TableExcelDownloadButtonProps = {
  rows: GridRowsProp;
  columns: GridColDef[];
  fileName?: string;
};

const TableExcelDownloadButton: React.FC<TableExcelDownloadButtonProps> = ({
  rows,
  columns,
  fileName = "table-data.xlsx",
}) => {
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // 컬럼 추가
    worksheet.columns = columns.map((col) => ({
      header: col.headerName || col.field,
      key: col.field,
      width: 20,
    }));

    // 데이터 추가
    rows.forEach((row) => {
      const rowData = columns.map((col) => row[col.field]);
      worksheet.addRow(rowData);
    });

    // 엑셀 파일 생성 및 다운로드
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  return (
    <Button variant="contained" color="primary" onClick={handleDownload}>
      Download Excel
    </Button>
  );
};

export default TableExcelDownloadButton;
