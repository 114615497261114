import { customAxios } from "../utils/CustomAxios";
import axios, { AxiosError } from "axios";
import { deToast } from "../utils/DeToast";
import { extErrDetails } from "../utils/ErrorHandleUtil";

export const submitMember = async (
  memberStatus: string,
  memberName: string,
  memberEngName: string,
  addr1: string,
  tel1: string,
  prfrArGrade: number
) => {
  try {
    const response = await customAxios.post("arbp/member", {
      memberStatus,
      memberName,
      memberEngName,
      addr1,
      tel1,
      prfrArGrade,
    });
    return response.data;
  } catch (error) {
    console.error("memberSimple process failed", error);
    throw new Error("memberSimple process failed");
  }
};

export const submitMemberEdit = async (
  memberNo: number,
  memberStatus: string,
  memberName: string,
  memberEngName: string,
  addr1: string,
  tel1: string,
  prfrArGrade: number
) => {
  try {
    const response = await customAxios.post("arbp/member/update", {
      memberNo,
      memberStatus,
      memberName,
      memberEngName,
      addr1,
      tel1,
      prfrArGrade,
    });
    return response.data;
  } catch (error) {
    console.error("memberSimple process failed", error);
    throw new Error("memberSimple process failed");
  }
};

export const scannedBookRentProc = async (
  memberNo: number,
  bookNo: string,
  rentOrReturnFlag: boolean = false,
  forceFlag: boolean = false,
  refDate: string
) => {
  try {
    const response = await customAxios.post(`arbp/rent/scanandrent`, {
      memberNo: memberNo,
      scannedBookNo: bookNo,
      rentOrReturnFlag: rentOrReturnFlag,
      forceFlag: forceFlag,
      refDate: refDate,
    });

    if (response.status === 200) {
      // You can adjust the response check based on your actual API behavior
      return {
        success: true,
        message: response.data || "Operation successful",
      };
    } else {
      return {
        success: false,
        message: response.data || "Unknown error occurred",
      };
    }
  } catch (error) {
    return {
      success: false,
      message: extErrDetails(error).message,
    };
  }
};

export const scannedBookReturnProc = async (
  memberNo: number,
  bookNo: string,
  rentOrReturnFlag: boolean = false,
  forceFlag: boolean = false,
  refDate: string
) => {
  try {
    const response = await customAxios.post(`arbp/return/scanandreturn`, {
      memberNo: memberNo,
      scannedBookNo: bookNo,
      rentOrReturnFlag: rentOrReturnFlag,
      forceFlag: forceFlag,
      refDate: refDate,
    });

    if (response.status === 200) {
      return {
        success: true,
        message: response.data || "Operation successful",
      };
    } else {
      return {
        success: false,
        message: response.data || "Unknown error occurred",
      };
    }
  } catch (error) {
    return {
      success: false,
      message: extErrDetails(error).message,
    };
  }
};

export const fetchMemberRented = async (memberNo: number) => {
  try {
    const response = await customAxios.get(
      `arbp/rents?memberNo=${memberNo}&rentStatus=Rented&size=100`
    );
    return response.data.data.content;
  } catch (error) {
    throw error;
  }
};

export const fetchRecommendations = async (
  memberNo: number,
  qty: number,
  scopeTimesTen: number
) => {
  try {
    const response = await customAxios.get(
      `arbp/recommend?memberNo=${memberNo}&qty=${qty}&scopeTimesTen=${scopeTimesTen}`
    );
    return response.data.data; // API 응답에서 data 필드 반환
  } catch (error) {
    const axiosError = error as AxiosError; // Cast error as AxiosError for type-safe access

    if (axiosError.isAxiosError) {
      // Check if it's an Axios error
      // Handle Axios-specific errors
      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 404:
            deToast(
              "Recommendations not found. Please check the member number.",
              404001
            );
            break;
          case 500:
            deToast("Server error occurred. Please try again later.", 500001);
            break;
          default:
            deToast(
              `An unexpected error occurred: ${axiosError.message}`,
              500002
            );
            break;
        }
      } else {
        // Network error or error not related to the HTTP response
        deToast(
          `Network or configuration error: ${axiosError.message}`,
          500003
        );
      }
    } else {
      // Non-Axios error
      deToast("An unexpected error occurred.", 500004);
    }
    throw error;
  }
};

export const rentBook = async (memberNo: number, bookNo: string) => {
  try {
    const response = await customAxios.post("arbp/rent", { memberNo, bookNo });
    return response.data;
  } catch (error) {
    console.error("Rent process failed", error);
    throw new Error("Rent process failed");
  }
};

export const returnBook = async (memberNo: number, bookNo: string) => {
  try {
    const response = await customAxios.post("arbp/return", {
      memberNo,
      bookNo,
    });
    return response.data;
  } catch (error) {
    console.error("Return process failed", error);
    throw new Error("Return process failed");
  }
};

export const returnAllByMember = async (
  // not used
  memberNo: number,
  rentStatus: string,
  mdfr: string
) => {
  try {
    const response = await customAxios.post("arbp/rent/updateallbymember", {
      memberNo,
      rentStatus,
      mdfr,
    });
    if (response.status === 200) {
      deToast(response.data || "", 0);
      return {
        success: true,
        message: response.data || "Operation successful",
      };
    } else {
      return {
        success: false,
        message: response.data || "Unknown error occurred",
      };
    }
  } catch (error) {
    deToast(extErrDetails(error).message || "", 400);
  }
};

interface ReturnBooksRequest {
  memberNo: number;
  bookNos: string[];
}

export const returnAllByBookNo = async (
  request: ReturnBooksRequest
): Promise<any> => {
  try {
    const response = await customAxios.post(
      "arbp/rent/returnAllByBookNo",
      request
    );
    if (response.status === 200) {
      deToast(response.data || "", 0);
    } else {
      deToast(response.data || "", response.status);
    }
  } catch (error) {
    deToast(extErrDetails(error).message || "", 400);
  }
};
