import React, { useEffect, useRef } from "react";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
  actions: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  content,
  actions,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // 모달 내부 첫 번째 요소로 자동 포커스
  useEffect(() => {
    if (open) {
      modalRef.current?.focus();
    }
  }, [open]);

  // 모달 밖 클릭시 닫기
  const handleBackgroundClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === modalRef.current) {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <div
      ref={modalRef}
      onClick={handleBackgroundClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000, // Ensure it's on top of everything else
      }}
    >
      <div
        style={{
          width: "500px",
          backgroundColor: "white",
          padding: "20px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
          borderRadius: "8px", // Rounded corners
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center align the items vertically
        }}
        tabIndex={-1}
      >
        <h2 style={{ textAlign: "center" }}>{title}</h2>
        <p style={{ textAlign: "center" }}>{content}</p>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {actions}
        </div>
      </div>
    </div>
  );
};

export default Modal;
