// atoms/memberAtom.ts 파일

import { atom } from "jotai";

interface MemberState {
  memberNo: number | null;
  memberName: string | null;
  memberEngName: string | null;
  qty: number;
  scopeTimesTen: number;
}

export const memberAtom = atom<MemberState>({
  memberNo: null,
  memberName: null,
  memberEngName: null,
  qty: 5,
  scopeTimesTen: 5,
});
