/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import {
  fetchRecommendations,
  rentBook,
  returnBook,
  scannedBookRentProc,
  scannedBookReturnProc,
  fetchMemberRented,
  returnAllByBookNo,
} from "../api/apiService";
import { useAtom } from "jotai";
import { authAtom } from "../atoms/authAtom";
import { memberAtom } from "../atoms/memberAtom";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
  TextField,
  Slider,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  InputAdornment,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../App.css";
import { syncBuiltinESMExports } from "module";
import Modal from "./Modal";
import { dateAtom } from "../atoms/atoms";
import { isMemberName } from "typescript";
import { deToast } from "../utils/DeToast";
import { extErrDetails } from "../utils/ErrorHandleUtil";

interface BarcodeReaderProps {
  taskType: "rent" | "return";
}

const BarcodeReader: React.FC<BarcodeReaderProps> = ({ taskType }) => {
  const [
    { memberNo, memberName, memberEngName, qty, scopeTimesTen },
    setMemberState,
  ] = useAtom(memberAtom);
  const [date, setDate] = useAtom(dateAtom);
  const [bookNo, setBookNo] = useState("");
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [memberRentedBooks, setMemberRentedBooks] = useState<any[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalActions, setModalActions] = useState<React.ReactNode>(null);
  const [authState] = useAtom(authAtom);
  const loginName = authState.tokenParsed?.preferred_username || "Unknown";

  useEffect(() => {
    if (taskType === "rent") {
      fetchData();
    }
    if (taskType === "return") {
      fetchDataMemberRented();
    }
  }, [memberNo, qty, scopeTimesTen]);

  useEffect(() => {
    if (taskType === "return") {
      fetchDataMemberRented();
    }
  }, []);

  const fetchDataMemberRented = async () => {
    try {
      const response = await fetchMemberRented(memberNo || 0);

      if (response) {
        setMemberRentedBooks(response);

        // 오늘 날짜와 동일한 due date가 있는 책을 사전 선택
        const today = new Date().toISOString().split("T")[0];
        const preSelectedBooks = response
          .filter((book: any) => book.returnDueDate === today)
          .map((book: any) => book.bookNo);
        setSelectedBooks(preSelectedBooks);
      } else {
        console.error("Failed to fetch rented books:", response.message);
      }
    } catch (error) {
      console.error("Failed to fetch rented books:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetchRecommendations(
        memberNo || 0,
        qty,
        scopeTimesTen
      );

      if (response) {
        setRecommendations(response);
      } else {
        console.error("Failed to fetch recommendations:", response.message);
      }
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    }
  };

  const handleQtyChange = (value: number) => {
    setMemberState((prev) => ({ ...prev, qty: value }));
  };

  const handleScopeTimesTenChange = (value: number) => {
    setMemberState((prev) => ({ ...prev, scopeTimesTen: value }));
  };

  const handleMemberNoChange = (value: string) => {
    setMemberState((prev) => ({ ...prev, memberNo: parseInt(value) || null }));
  };

  const scanRentPost = async (memberNo: number, decodedText: string) => {
    try {
      const scannedBookProcRes = await scannedBookRentProc(
        memberNo || 0,
        decodedText,
        true,
        false,
        date
      );
      if (scannedBookProcRes.success) {
        deToast("대여 처리 되었습니다", 0);
        setBookNo("");
      } else {
        deToast("오류. 관리자에게 문의하세요", 400001);
      }
    } catch (error) {
      deToast(extErrDetails(error).message || "error", 400002);
      setBookNo("");
      console.error("Error fetching data:", error);
    }
    setShowModal(false);
  };

  const scanReturnPost = async (memberNo: number, decodedText: string) => {
    try {
      const scannedBookProcRes = await scannedBookReturnProc(
        memberNo || 0,
        decodedText,
        true,
        false,
        date
      );
      if (scannedBookProcRes.success) {
        deToast("반납 처리 되었습니다", 0);
        setBookNo("");
      } else {
        deToast("오류. 관리자에게 문의하세요", 400003);
      }
    } catch (error) {
      deToast(extErrDetails(error).message || "error", 400004);
      setBookNo("");
      console.error("Error fetching data:", error);
    }
    setShowModal(false);
    fetchDataMemberRented();
  };

  const onNewScanResult = async (decodedText: string, decodedResult: any) => {
    try {
      if (taskType === "rent" && memberNo) {
        const scannedBookProcRes = await scannedBookRentProc(
          memberNo || 0,
          decodedText,
          false,
          false,
          date
        );

        console.log("scannedBookProc:", scannedBookProcRes);
        if (scannedBookProcRes.success) {
          setModalContent(scannedBookProcRes.message);
          setModalActions(
            <Button
              variant="outlined"
              color="primary"
              onClick={() => scanRentPost(memberNo, decodedText)}
            >
              대여
            </Button>
          );
        } else {
          setModalContent(scannedBookProcRes.message);
          setModalActions(
            <Button
              variant="outlined"
              color="primary"
              onClick={handleConfirmCloseModal}
            >
              확인
            </Button>
          );
        }
        setShowModal(true);
      } else if (taskType === "return" && memberNo) {
        // 반납 처리
        const scannedBookProcRes = await scannedBookReturnProc(
          memberNo || 0,
          decodedText,
          false,
          false,
          date
        );

        console.log("scannedBookProc:", scannedBookProcRes);
        if (scannedBookProcRes.success) {
          setModalContent(scannedBookProcRes.message);
          setModalActions(
            <Button
              variant="outlined"
              color="primary"
              onClick={() => scanReturnPost(memberNo, decodedText)}
            >
              반납
            </Button>
          );
        } else {
          setModalContent(scannedBookProcRes.message);
          setModalActions(
            <Button
              variant="outlined"
              color="primary"
              onClick={handleConfirmCloseModal}
            >
              확인
            </Button>
          );
        }
        setShowModal(true);
      }
    } catch (error) {
      deToast(extErrDetails(error).message || "error", 400005);
      setBookNo("");
      console.error("Error fetching data:", error);
    }
  };

  const handleConfirmCloseModal = () => {
    setShowModal(false);
    setBookNo("");
  };

  // 입력 필드의 값이 변경될 때마다 호출되는 함수
  const handleBookNoInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBookNo(event.target.value.trim());
  };

  const handleToggle = (bookNo: string) => () => {
    const currentIndex = selectedBooks.indexOf(bookNo);
    const newSelectedBooks = [...selectedBooks];

    if (currentIndex === -1) {
      newSelectedBooks.push(bookNo);
    } else {
      newSelectedBooks.splice(currentIndex, 1);
    }

    setSelectedBooks(newSelectedBooks);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allBooks = memberRentedBooks.map((book) => book.bookNo);
      setSelectedBooks(allBooks);
      setSelectAll(true);
    } else {
      setSelectedBooks([]);
      setSelectAll(false);
    }
  };

  const formatsToSupport = [
    Html5QrcodeSupportedFormats.CODE_128,
    Html5QrcodeSupportedFormats.CODE_39,
    Html5QrcodeSupportedFormats.CODE_93,
    Html5QrcodeSupportedFormats.UPC_A,
    Html5QrcodeSupportedFormats.UPC_E,
    Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
  ];

  const onBulkReturn = async () => {
    if (memberNo === null) {
      deToast("Member number is not available.", 400);
      return;
    }

    await returnAllByBookNo({
      memberNo,
      bookNos: selectedBooks,
    });

    setSelectedBooks([]);
    setSelectAll(false);
    fetchDataMemberRented();
  };

  return (
    <div className="content">
      <div className="BarcodeReader">
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
          formatsToSupport={formatsToSupport}
        />

        <div id="reader"></div>

        {taskType === "rent" && (
          <>
            <Box sx={{ width: "100%", padding: 2 }}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="book-no">Book No</InputLabel>
                <Input
                  id="book-no"
                  type="text"
                  value={bookNo} // defaultValue -> value 로 바인드
                  onChange={handleBookNoInputChange}
                  endAdornment={
                    <InputAdornment position="end">#</InputAdornment>
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={() => onNewScanResult(bookNo, "")}
              >
                렌트
              </Button>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="member-no">Member No</InputLabel>
                <Input
                  id="member-no"
                  type="text"
                  value={
                    (memberNo || "") +
                    " ( " +
                    (memberName || "") +
                    " / " +
                    (memberEngName || "") +
                    " )"
                  }
                  // onChange={(e) => handleMemberNoChange(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">#</InputAdornment>
                  }
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="quantity">Quantity</InputLabel>
                <Input
                  id="quantity"
                  type="number"
                  value={qty}
                  onChange={(e) => handleQtyChange(parseInt(e.target.value))}
                  startAdornment={
                    <InputAdornment position="start">Qty</InputAdornment>
                  }
                />
              </FormControl>

              <Typography id="input-slider" gutterBottom>
                Scope
              </Typography>
              <Slider
                value={typeof scopeTimesTen === "number" ? scopeTimesTen : 0}
                onChange={(event, newValue) => {
                  // Ensure that newValue is treated as a number, not a number[]
                  const value = Array.isArray(newValue)
                    ? newValue[0]
                    : newValue; // Fallback to the first item if it's an array
                  handleScopeTimesTenChange(value);
                }}
                aria-labelledby="input-slider"
                min={0}
                max={10}
                step={1}
                marks
                valueLabelDisplay="auto"
              />
              <Typography>± {scopeTimesTen / 10}</Typography>
            </Box>

            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Button
                onClick={fetchData}
                color="primary"
                startIcon={<RefreshIcon />}
                sx={{
                  borderRadius: 3, // 둥근 모서리 적용
                  padding: "6px 12px", // 버튼 내부 패딩 조정
                  textTransform: "none", // 버튼 텍스트 대문자 변환 제거
                  marginLeft: "32px",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.2)", // 그림자 효과 추가
                }}
              >
                추천 리스트
              </Button>

              <List dense={true}>
                {recommendations.map((book, index) => (
                  <ListItem key={index} divider>
                    <ListItemText
                      primary={`${book.bookNo}, ${book.title}`}
                      secondary={`Author: ${book.author}, AR Level: ${book.arLvl}, Points: ${book.points}, Word Count: ${book.wordCnt}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}

        {taskType === "return" && memberNo && (
          <>
            <Box sx={{ width: "100%", padding: 2 }}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="member-no">Member No</InputLabel>
                <Input
                  id="member-no"
                  type="text"
                  value={
                    (memberNo || "") +
                    " ( " +
                    (memberName || "") +
                    " / " +
                    (memberEngName || "") +
                    " )"
                  }
                  // onChange={(e) => handleMemberNoChange(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">#</InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <br />
              <p>[개별 반납]</p>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="book-no">Book No</InputLabel>
                <Input
                  id="book-no"
                  type="text"
                  defaultValue={bookNo || ""}
                  onChange={handleBookNoInputChange}
                  endAdornment={
                    <InputAdornment position="end">#</InputAdornment>
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={() => onNewScanResult(bookNo, "")}
              >
                반납
              </Button>
              <br />
              <br />
              <br />
              <p>[반납 대상 리스트]</p>
              <FormControlLabel
                control={
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                }
                label="전체 선택"
              />
              <List dense={true}>
                {memberRentedBooks.map((book, index) => (
                  <ListItem key={index} divider>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedBooks.indexOf(book.bookNo) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `checkbox-list-label-${index}`,
                        }}
                        onChange={handleToggle(book.bookNo)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${book.bookNo}, ${book.title}`}
                      secondary={`Return Due Date: ${
                        book.returnDueDate
                      }, Extend Count: ${
                        book.extendCnt !== null ? book.extendCnt : "N/A"
                      }, Created Time: ${
                        book.crtTime !== null ? book.crtTime : "N/A"
                      }`}
                    />
                  </ListItem>
                ))}
              </List>
              <Button variant="contained" onClick={onBulkReturn}>
                선택된 도서 일괄 반납
              </Button>
            </Box>
          </>
        )}

        {/* 모달 컴포넌트 렌더링 */}
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          title="대여 프로세스 메세지"
          content={modalContent}
          actions={modalActions}
        />
      </div>
    </div>
  );
};

export default BarcodeReader;
