import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import "tailwindcss/tailwind.css";
import "../App.css";
import { customAxios } from "../utils/CustomAxios";
import { useAtom } from "jotai";
import { authAtom } from "../atoms/authAtom";
import { deToast } from "../utils/DeToast";
import { extErrDetails } from "../utils/ErrorHandleUtil";
import TableExcelDownloadButton from "./TableExcelDownloadButton";

interface BookIf {
  bookNo: string;
  arNo: number;
  bookStatus: string;
  arLvl: number;
  lexileLvl?: string;
  title: string;
  author: string;
  rqQuizNo?: number;
  il: string;
  points: number;
  fiction: string;
  rating?: string;
  wordCnt: number;
  rp?: string;
  rv?: string;
  vp?: string;
  ls?: string;
  isbn?: string;
  audioQr?: string;
  crtr?: string;
  crtTime?: string;
  mdfr?: string;
  mdfTime?: string;
}

interface ApiResponse {
  code: number;
  message: string;
  data: {
    content: BookIf[];
    totalPages: number;
    totalElements: number;
  };
}

const filterOptions = [
  { value: "bookNo", label: "bookNo", type: "text" },
  { value: "arNo", label: "arNo", type: "text" },
  { value: "arLvl", label: "arLvl", type: "text" },
  { value: "title", label: "title", type: "text" },
];

const Book: React.FC = () => {
  const [authState] = useAtom(authAtom);
  const [arno, setArno] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isConflict, setIsConflict] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false); // 모달 창 열림 상태
  // grid function
  const [books, setBooks] = useState<BookIf[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[0].value
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const [pagination, setPagination] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const columns: GridColDef[] = [
    { field: "bookNo", headerName: "bookNo", width: 150 },
    { field: "arNo", headerName: "arNo", width: 100 },
    {
      field: "bookStatus",
      headerName: "bookStatus",
      width: 100,
      renderCell: (params) => {
        if (params.value !== "Available") {
          return <span style={{ color: "red" }}>{params.value}</span>;
        }
        return null;
      },
    },

    { field: "arLvl", headerName: "arLvl", width: 150 },
    { field: "lexileLvl", headerName: "lexileLvl", width: 150 },
    { field: "title", headerName: "title", width: 200 },
    { field: "author", headerName: "author", width: 200 },
    { field: "rqQuizNo", headerName: "rqQuizNo", width: 100 },
    { field: "il", headerName: "il", width: 100 },
    { field: "points", headerName: "points", width: 100 },
    { field: "fiction", headerName: "fiction", width: 100 },
    { field: "rating", headerName: "rating", width: 100 },
    { field: "wordCnt", headerName: "wordCnt", width: 100 },
    { field: "rp", headerName: "rp", width: 50 },
    { field: "rv", headerName: "rv", width: 50 },
    { field: "vp", headerName: "vp", width: 50 },
    { field: "ls", headerName: "ls", width: 50 },
    { field: "isbn", headerName: "isbn", width: 150 },
    { field: "audioQr", headerName: "audioQr", width: 150 },
    { field: "crtr", headerName: "crtr", width: 150 },
    { field: "crtTime", headerName: "crtTime", width: 200 },
    { field: "mdfr", headerName: "mdfr", width: 150 },
    { field: "mdfTime", headerName: "mdfTime", width: 200 },
  ];
  const fetchBooks = async () => {
    setLoading(true);
    const params = new URLSearchParams({
      page: (pagination.page + 1).toString(),
      size: pagination.pageSize.toString(),
      ...(filterValue && { [selectedFilter]: filterValue }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
    });

    try {
      const response = await customAxios.get<ApiResponse>(
        `/arbp/books?${params.toString()}`
      );
      if (response.data.code === 200) {
        setBooks(response.data.data.content);
        setTotalElements(response.data.data.totalElements);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Failed to fetch books:", error);
      deToast(extErrDetails(error).message || "Failed to fetch books", 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, [
    selectedFilter,
    filterValue,
    pagination.page,
    pagination.pageSize,
    fromDate,
    toDate,
    authState,
  ]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setSelectedFilter(event.target.value);
    setFilterValue(""); // Reset filter value on filter change
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPagination({
      page: newModel.page,
      pageSize: newModel.pageSize,
    });
  };

  // end of grid function

  const handleAddBook = async (mode: string) => {
    setLoading(true);
    try {
      const response = await customAxios.post("/arbp/book/new", null, {
        params: { arno, mode },
      });
      if (response.status === 200) {
        deToast("success", response.status);
        setErrorMessage("");
        setIsConflict(false);
        setOpen(false);
        setArno("");
      } else {
        deToast(response.data || "", response.status);
      }
    } catch (error: unknown) {
      const errorDetails = extErrDetails(error);
      if (errorDetails.code === 409) {
        setErrorMessage(errorDetails.message || "");
        setIsConflict(true);
        setOpen(true); // 모달 창 열기
      } else {
        deToast(errorDetails.message || "", 0);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAddBook = async () => {
    await handleAddBook("add");
  };

  return (
    <div className="content">
      <Box className="p-4">
        <h1 className="text-xl font-bold mb-4">도서 추가</h1>
        <TextField
          type="number"
          label="AR 번호"
          value={arno}
          onChange={(e) => setArno(e.target.value || "")}
          variant="outlined"
          fullWidth
          className="mb-4"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddBook("check")}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "추가"}
        </Button>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>도서 추가 확인</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              취소
            </Button>
            <Button onClick={handleConfirmAddBook} color="primary" autoFocus>
              추가
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box className="p-4">
        <h1 className="text-xl font-bold mb-4">도서 목록</h1>
        <Box display="flex" alignItems="center" mb={2}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="filter-label">Search By</InputLabel>
            <Select
              labelId="filter-label"
              value={selectedFilter}
              label="Search By"
              onChange={handleFilterChange}
            >
              {filterOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label={
              filterOptions.find((option) => option.value === selectedFilter)
                ?.label
            }
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
            margin="normal"
            variant="outlined"
            className="mr-2"
          />
          <TextField
            label="From Date"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            variant="outlined"
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            variant="outlined"
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={fetchBooks}
            className="my-2"
          >
            Apply Filters
          </Button>
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="overflow-x-auto overflow-y-hidden w-full"> {/* 좌우 스크롤 허용, 상하 스크롤 제거 */}
            <DataGrid
              rows={books}
              columns={columns}
              paginationModel={pagination}
              onPaginationModelChange={handlePaginationModelChange}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              pagination
              paginationMode="server"
              rowCount={totalElements}
              getRowId={(row) => row.bookNo} // 고유 ID를 지정
            />
          </div>
        )}
        <TableExcelDownloadButton
          rows={books}
          columns={columns}
          fileName="books-data.xlsx"
        />
      </Box>
    </div>
  );
};

export default Book;
