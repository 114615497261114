import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppPublic from "./AppPublic";
import reportWebVitals from "./reportWebVitals";
import keycloak from "./auth/Keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

const initOptions = {
  onLoad: "check-sso", //,onLoad: 'login-required',
  checkLoginIframe: true,
  pkceMethod: "S256",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
    <AppPublic />
  </ReactKeycloakProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
