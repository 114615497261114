import { debounce } from "lodash";
import { toast } from "react-toastify";

// Typing the parameters and the function return type
const debouncedToast = debounce(
  (message: string, errorCode: number): void => {
    const toastId = `toast-${errorCode}`;
    console.log(toastId);
    if (!toast.isActive(toastId)) {
      console.log(111);
      if (errorCode >= 300) {
        console.log(222);
        toast.error(message, { toastId });
      } else {
        console.log(333);
        toast.success(message, { toastId });
        console.log("333post");
      }
    }
  },
  1000,
  { leading: true, trailing: false }
);

export const deToast = (message: string, errorCode: number): void => {
  console.log(1212);
  debouncedToast(message, errorCode);
};
