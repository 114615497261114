// src/atoms/authAtom.ts
import { atom } from "jotai";
import { KeycloakTokenParsed } from "keycloak-js";

interface AuthState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  tokenParsed: KeycloakTokenParsed | undefined;
}

export const authAtom = atom<AuthState>({
  accessToken: undefined,
  refreshToken: undefined,
  tokenParsed: undefined,
});
