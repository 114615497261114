import React, { useEffect } from "react";

const PublicHome: React.FC = () => {
  useEffect(() => {
    // 인스타그램 페이지로 리다이렉트
    window.location.href = "https://www.instagram.com/ar_bookplay";
  }, []);

  return (
    <div>
      <p>Redirecting to Instagram...</p>
    </div>
  );
};

export default PublicHome;
