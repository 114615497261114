// ErrorHandleUtil.ts
import axios, { AxiosError } from "axios";

interface ErrorResponseData {
  message?: string;
  code?: string | number;
}

interface ErrorDetails {
  message?: string;
  code?: string | number;
}

export function extErrDetails(error: unknown): ErrorDetails {
  const details: ErrorDetails = {};

  if (axios.isAxiosError(error)) {
    const e = error as AxiosError<ErrorResponseData>; // 여기에서 에러 응답 데이터 타입을 지정

    if (e.response && e.response.data) {
      if (typeof e.response.data.message === "string") {
        details.message = e.response.data.message;
      }
      if (e.response.data.code) {
        details.code = e.response.data.code;
      }
    }

    if (!details.message && e.message) {
      details.message = e.message;
    }
  } else {
    details.message = "An unexpected error occurred";
  }

  return details;
}
