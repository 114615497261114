import React from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  memberForm: {
    memberName: string;
    memberEngName: string;
    memberStatus: string;
    prfrArGrade: number;
  };
  errors: {
    memberName: string;
    memberEngName: string;
    prfrArGrade: number;
  };
  formSubmitting: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseAddMember: () => void;
  handleAddMember: () => void;
  isAddMemberOpen: boolean;
}

const MemberForm: React.FC<Props> = ({
  memberForm,
  errors,
  formSubmitting,
  handleInputChange,
  handleCloseAddMember,
  handleAddMember,
  isAddMemberOpen,
}) => (
  <Dialog open={isAddMemberOpen} onClose={handleCloseAddMember}>
    <DialogTitle>
      {memberForm.memberName ? "Edit Member" : "Add New Member"}
    </DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        name="memberName"
        label="이름 (예시 : 홍길동)"
        type="text"
        fullWidth
        variant="outlined"
        value={memberForm.memberName}
        onChange={handleInputChange}
        error={!!errors.memberName}
        helperText={errors.memberName}
      />
      <TextField
        margin="dense"
        name="memberEngName"
        label="영문이름 혹은 예명 (예시 : ted)"
        type="text"
        fullWidth
        variant="outlined"
        value={memberForm.memberEngName}
        onChange={handleInputChange}
        error={!!errors.memberEngName}
        helperText={errors.memberEngName}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel>상태</InputLabel>
        <Select
          name="memberStatus"
          value={memberForm.memberStatus}
          onChange={(event: SelectChangeEvent<string>) =>
            handleInputChange({
              target: { name: event.target.name, value: event.target.value },
            } as any)
          }
        >
          <MenuItem value="ACTIVE">Active</MenuItem>
          <MenuItem value="INACTIVE">Inactive</MenuItem>
          <MenuItem value="SUSPENDED">Suspended</MenuItem>
          <MenuItem value="PENDING_ACTIVATION">Pending Activation</MenuItem>
          <MenuItem value="DELETED">Deleted</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        name="prfrArGrade"
        label="원하는 AR 레벨 평균 (예시 : 2.8)"
        type="number"
        inputProps={{ step: "0.1" }} // 소수점 한 자리까지 입력 가능
        fullWidth
        variant="outlined"
        value={memberForm.prfrArGrade || ""}
        onChange={handleInputChange}
        error={!!errors.prfrArGrade}
        helperText={errors.prfrArGrade}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseAddMember}>Cancel</Button>
      <Button onClick={handleAddMember} disabled={formSubmitting}>
        {formSubmitting ? (
          <CircularProgress size={24} />
        ) : memberForm.memberName ? (
          "Save Changes"
        ) : (
          "Add Member"
        )}
      </Button>
    </DialogActions>
  </Dialog>
);

export default MemberForm;
